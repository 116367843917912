<template>
  <div>
    <div :class="$style.floatingLoading" v-if="getDataLoading">
      <div
        class="d-flex"
        style="
          border: 1px solid #e8e9eb;
          width: 120px;
          padding: 10px;
          border-radius: 10px;
        "
      >
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 20px"
            class="mr-3 mb-0"
            spin
          />
        </a-spin>
        Loading...
      </div>
    </div>
    <div
      class="d-flex justify-content-between"
      :class="[this.isMobile ? 'row' : 'flex-row']"
    >
      <div class="col-xl-5">
        <a-input
          placeholder="Ketik nama atau tanggal lahir untuk mencari pasien..."
          v-model="searchQuery"
          style="margin-bottom: 16px; width: 100%"
        >
          <template #prefix>
            <a-icon type="search" />
          </template>
        </a-input>

        <div class="mb-4" style="z-index: 1">
          <a-table
            :columns="columns"
            :dataSource="filteredData"
            @rowClick="onRowClick"
            :rowKey="(record) => record.id"
          >
          </a-table>
        </div>
      </div>
      <div>
        <a-divider type="vertical" style="min-height: 100%"></a-divider>
      </div>
      <div class="col-xl-7">
        <div v-if="Object.keys(this.selectedRowData).length === 0" class="mt-5">
          <a-empty :description="false" />
          <div class="text-center">
            Mohon pilih pasien terlebih dahulu untuk melihat detail pasien
          </div>
        </div>
        <div v-else class="">
          <div :class="$style.textTitle" class="mb-2">Detail Pasien</div>
          <div :class="$style.textContent" class="mb-1">
            {{ this.selectedRowData.name }}
          </div>
          <div :class="$style.textContent">
            {{ this.selectedRowData.birth_place }},
            {{ this.selectedRowData.birth_date }}
          </div>
          <button
            v-if="this.role && this.role !== 'dokter'"
            :class="$style.button"
            class="mt-3 mb-2"
            @click="handleTambahKunjungan"
          >
            <div class="fas fa-plus" />
            Registrasi Kunjungan
          </button>

          <a-tabs class="mb-2" :activeKey="activeKey" @change="handleTabChange">
            <a-tab-pane key="1" tab="Identitas Pasien">
              <div class="card">
                <div class="m-3">
                  <table :class="$style.table">
                    <tr>
                      <td style="width: 25%">Nama Lengkap</td>
                      <td style="width: 75%">
                        {{ this.selectedRowData.name }}
                      </td>
                    </tr>
                    <tr>
                      <td>Jenis Kelamin</td>
                      <td v-if="this.selectedRowData.gender === 'Female'">
                        Perempuan
                      </td>
                      <td v-if="this.selectedRowData.gender === 'Male'">
                        Laki-laki
                      </td>
                    </tr>
                    <tr>
                      <td>Tempat Lahir</td>
                      <td>{{ this.selectedRowData.birth_place }}</td>
                    </tr>
                    <tr>
                      <td>Tanggal Lahir</td>
                      <td>{{ this.selectedRowData.birth_date }}</td>
                    </tr>
                    <tr>
                      <td>Usia</td>
                      <td>{{ this.selectedRowData.age }}</td>
                    </tr>
                    <br />
                    <tr>
                      <td>No. Rekam Medis</td>
                      <td>{{ this.selectedRowData.medical_record_no }}</td>
                    </tr>
                    <tr>
                      <td>NIK</td>
                      <td>{{ this.selectedRowData.NIK }}</td>
                    </tr>
                    <tr>
                      <td>Cara Pembayaran</td>
                      <td>{{ this.selectedRowData.payment_method }}</td>
                    </tr>
                    <tr v-if="this.selectedRowData.payment_method === 'JKN'">
                      <td>No. BPJS Kesehatan</td>
                      <td>{{ this.selectedRowData.bpjs_no }}</td>
                    </tr>
                    <tr
                      v-if="this.selectedRowData.payment_method === 'Asuransi'"
                    >
                      <td>Nama Asuransi</td>
                      <td>{{ this.selectedRowData.insurance_name }}</td>
                    </tr>
                    <tr
                      v-if="this.selectedRowData.payment_method === 'Asuransi'"
                    >
                      <td>No. Asuransi</td>
                      <td>{{ this.selectedRowData.insurance_no }}</td>
                    </tr>
                    <br />
                    <tr>
                      <td>No. Telepon Rumah</td>
                      <td v-if="this.selectedRowData.telephone_no">
                        {{ this.selectedRowData.telephone_no }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                    <tr>
                      <td>No. Telepon Selular</td>
                      <td>{{ this.selectedRowData.phone_no }}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td v-if="this.selectedRowData.email">
                        {{ this.selectedRowData.email }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                    <tr>
                      <td
                        style="width: 25%"
                        v-if="
                          this.selectedRowData.identity_nation === 'Indonesia'
                        "
                      >
                        Alamat KTP
                      </td>
                      <td style="width: 25%" v-else>Alamat Identitas</td>
                      <td
                        style="width: 75%"
                        v-if="
                          this.selectedRowData.identity_nation === 'Indonesia'
                        "
                      >
                        {{ this.selectedRowData.identity_address }}, RT:
                        {{ this.selectedRowData.identity_RT }}, RW:
                        {{ this.selectedRowData.identity_RW }},
                        {{ this.selectedRowData.identity_village }},
                        {{ this.selectedRowData.identity_district }},
                        {{ this.selectedRowData.identity_regency }},
                        {{ this.selectedRowData.identity_province }},
                        {{ this.selectedRowData.identity_postal_code }}
                      </td>
                      <td style="width: 75%" v-else>
                        {{ this.selectedRowData.identity_address }},
                        {{ this.selectedRowData.identity_nation }}
                      </td>
                    </tr>
                    <br />
                    <tr>
                      <td>Nama Ibu Kandung</td>
                      <td>
                        {{
                          this.selectedRowData.biological_mother_name
                            ? this.selectedRowData.biological_mother_name
                            : "-"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Agama</td>
                      <td>
                        {{
                          this.selectedRowData.religion
                            ? this.selectedRowData.religion
                            : "-"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Suku</td>
                      <td>
                        {{
                          this.selectedRowData.ethnic
                            ? this.selectedRowData.ethnic
                            : "-"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Bahasa</td>
                      <td>
                        {{
                          this.selectedRowData.language
                            ? this.selectedRowData.language
                            : "-"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Pendidikan</td>
                      <td>
                        {{
                          this.selectedRowData.education
                            ? this.selectedRowData.education
                            : "-"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Pekerjaan</td>
                      <td>
                        {{
                          this.selectedRowData.job
                            ? this.selectedRowData.job
                            : "-"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Menikah</td>
                      <td>
                        {{
                          this.selectedRowData.martial_status
                            ? this.selectedRowData.martial_status
                            : "-"
                        }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Penunjang">
              <div class="card">
                <div class="m-3" v-if="showDokumenPenunjang === false">
                  <p v-if="this.selectedRowPenunjang.length === 0">Tidak ada</p>
                  <div v-else>
                    <ul :class="$style.fileList">
                      <li
                        v-for="(file, index) in this.selectedRowPenunjang"
                        :key="index"
                      >
                        <div class="font-weight-bold">
                          {{ formatDateTimeFile(file.createdAt) }}
                        </div>
                        <div>Diunggah oleh {{ file.created_by_name }}</div>
                        <div
                          class="d-flex align-items-center"
                          :class="$style.listBorder"
                        >
                          <div>
                            <img
                              v-if="file.media_mime_type === 'application/pdf'"
                              src="resources/images/pdf-icon.svg"
                              alt="Document"
                            />
                            <img
                              v-else
                              :src="file.uri"
                              style="height: 48px; width: 48px"
                            />
                          </div>
                          <a
                            @click="openDokumenPenunjang(file)"
                            target="_blank"
                            class="ml-2"
                            >{{ file.name }}</a
                          >
                        </div>
                        <!-- <a-divider/> -->
                      </li>
                    </ul>
                  </div>
                </div>
                <div v-else class="m-3">
                  <div class="d-flex align-items-center justify-content-start">
                    <a @click="showDokumenPenunjang = false">
                      <img
                        src="resources/images/left-arrow-icon.svg"
                        alt="Icon"
                        style="width: 8px; height: 12px; margin-right: 8px"
                      />
                    </a>
                    <p style="margin: 0">
                      {{
                        formatDateTimeFile(selectedDokumenPenunjang.createdAt)
                      }}
                      | Diunggah Oleh
                      {{ selectedDokumenPenunjang.created_by_name }}
                    </p>
                  </div>
                  <br />
                  <div
                    v-if="
                      this.selectedDokumenPenunjang.media_mime_type !==
                      'application/pdf'
                    "
                  >
                    <object
                      :data="this.selectedDokumenPenunjang.uri"
                      :type="this.selectedDokumenPenunjang.media_mime_type"
                      width="100%"
                      min-height="100%"
                    ></object>
                  </div>
                  <div v-else style="min-width: 50vw">
                    <object
                      :data="this.selectedDokumenPenunjang.uri"
                      :type="this.selectedDokumenPenunjang.media_mime_type"
                      width="100%"
                      height="1200px"
                    ></object>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="3" tab="CPPT">
              <div class="card">
                <div class="m-3" style="overflow-x: auto">
                  <a-table
                    :columns="historyColumns"
                    :dataSource="selectedRowHistory"
                    :class="$style.stripedTable"
                    @rowClick="onRowHistoryClick"
                  />
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Table } from "ant-design-vue"
import { getAllPatientData, getById } from "@/services/axios/api/pasien"
import { getUserProfileFromId } from "@/services/axios/api/auth"
import {
  getDokumenPenunjangByDocId,
  getPatientVisit,
} from "@/services/axios/api/kunjungan"
import { getLanguageCode } from "@/services/axios/api/languageCode"
export default {
  components: {
    "a-table": Table,
  },
  computed: {
    filteredData() {
      if (!this.searchQuery.trim()) {
        return this.dataSource
      }
      return this.dataSource.filter((item) => {
        const matchesName = item.name
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase())
        const matchesBirthPlace = item.birth_place
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase())
        const matchesBirthDate = item.birth_date
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase())
        return matchesName || matchesBirthPlace || matchesBirthDate
      })
    },
  },
  methods: {
    handleTabChange(key) {
      this.activeKey = key
      this.showDokumenPenunjang = false
    },
    changeTab(key) {
      this.activeKey = key
      this.showDokumenPenunjang = false
    },
    calculateAge(birthdate) {
      const today = new Date()
      const birthDate = new Date(birthdate)

      const years = today.getFullYear() - birthDate.getFullYear()
      const months = today.getMonth() - birthDate.getMonth()
      const days = today.getDate() - birthDate.getDate()

      let ageYears = years
      let ageMonths = months
      let ageDays = days

      if (ageDays < 0) {
        const prevMonth = new Date(today.getFullYear(), today.getMonth(), 0)
        ageDays += prevMonth.getDate()
        ageMonths -= 1
      }

      if (ageMonths < 0) {
        ageMonths += 12
        ageYears -= 1
      }

      return `${ageYears} tahun, ${ageMonths} bulan, ${ageDays} hari`
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" }
      const date = new Date(dateString)
      return date.toLocaleDateString("id-ID", options)
    },
    async onRowClick(record) {
      this.changeTab("1")
      console.log(record)
      if (record.language === "id-ID") {
        const language = "Indonesia"
        record.language = language
      } else {
        const { concept } = await getLanguageCode()
        record.language = concept.filter(
          (e) => e.code === record.language,
        )[0].display
      }
      // const language = concept.filter((e) => e.code === record.language)
      this.selectedRowData = record
      // this.selectedRowData.language = language
      const dataPasien = await getById(record.id)
      // console.log(dataPasien, 'data pasien');

      const dataPenunjang = dataPasien.documents
      this.selectedRowHistoryId = dataPasien.visits

      this.selectedRowHistory = []

      for (const history in this.selectedRowHistoryId) {
        const res = await getPatientVisit(this.selectedRowHistoryId[history].id)
        res.data.visit.doctor_name = await this.getUserName(
          res.data.visit.attending_doctor_id,
        )
        res.data.visit.createdAtFormatted = this.formatDateTimeFile(
          res.data.visit.createdAt,
        )
        this.selectedRowHistory.push(res.data.visit)
        if (res.data.visit.status === "Selesai") {
          res.data.visit.doctor_name = await this.getUserName(
            res.data.visit.attending_doctor_id,
          )
          res.data.visit.createdAtFormatted = this.formatDateTimeFile(
            res.data.visit.createdAt,
          )

          let keluhan = ""
          for (const keluhanUtama in res.data.visit.Anamnesis.ChiefComplaints) {
            if (res.data.visit.Anamnesis.ChiefComplaints.length === 1) {
              keluhan +=
                res.data.visit.Anamnesis.ChiefComplaints[keluhanUtama]
                  .keterangan
            } else {
              if (
                keluhanUtama >
                res.data.visit.Anamnesis.ChiefComplaints.length - 2
              ) {
                keluhan +=
                  res.data.visit.Anamnesis.ChiefComplaints[keluhanUtama]
                    .keterangan
              } else {
                keluhan +=
                  res.data.visit.Anamnesis.ChiefComplaints[keluhanUtama]
                    .keterangan + ", "
              }
            }
          }

          res.data.visit.Anamnesis.keluhan_utama = keluhan
          this.selectedRowHistory.push(res.data.visit)
        }
      }

      // console.log(this.selectedRowHistory, 'selectedRowHistory');

      for (const file in dataPenunjang) {
        // console.log(dataPenunjang[file], "file");
        // console.log(dataPenunjang[file].id, "file id");
        dataPenunjang[file].created_by_name = await this.getUserName(
          dataPenunjang[file].created_by,
        )
        dataPenunjang[file].uri = await this.getUri(dataPenunjang[file].id)
      }
      this.selectedRowPenunjang = dataPenunjang
      // console.log(this.selectedRowPenunjang, 'selectedRowPenunjang');
      // console.log(this.selectedRowHistoryId, 'selectedRowHistoryId');
    },

    onRowHistoryClick(record) {
      console.log(record, "isi recordnya")
      const id = record.id
      this.$router.push(`/pemeriksaan/detail/${id}`)
    },

    handleTambahKunjungan() {
      this.$router.push(`/kunjungan/tambah/${this.selectedRowData.id}`)
    },

    async getUserName(id) {
      try {
        const response = await getUserProfileFromId(id)
        return response.data.user.name
      } catch (error) {
        console.error("Error fetching user name:", error)
        return "Unknown"
      }
    },

    async getUri(fileId) {
      const response = await getDokumenPenunjangByDocId(fileId)
      const url = URL.createObjectURL(response.data)
      return url
    },

    formatDateTimeFile(dateString) {
      const date = new Date(dateString)
      const dateOptions = { year: "numeric", month: "long", day: "2-digit" }
      const timeOptions = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }

      const formattedDate = date
        .toLocaleDateString("id-ID", dateOptions)
        .split("/")
        .join("-")
      const formattedTime = date
        .toLocaleTimeString("id-ID", timeOptions)
        .split(".")
        .join(":")

      return `${formattedDate} ${formattedTime}`
    },

    checkScreenWidth() {
      this.isMobile = window.innerWidth <= 767
    },

    openDokumenPenunjang(file) {
      this.showDokumenPenunjang = true
      this.selectedDokumenPenunjang = file
      console.log(this.selectedDokumenPenunjang, "selectedDokumenPenunjang")
    },
  },

  async mounted() {
    this.getDataLoading = true
    // console.log("sini");
    this.checkScreenWidth()
    window.addEventListener("resize", this.checkScreenWidth)
    const data = await getAllPatientData()
    this.dataSource = data.patients.map((patient) => {
      return {
        ...patient,
        age: this.calculateAge(patient.birth_date),
        birth_date: this.formatDate(patient.birth_date),
      }
    })
    this.dataSource = this.dataSource.sort((a, b) =>
      a.name.localeCompare(b.name),
    )
    // console.log(this.dataSource);
    this.role = localStorage.getItem("role")
    this.getDataLoading = false
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenWidth)
  },
  data() {
    return {
      historyColumns: [
        {
          title: "No.",
          key: "no",
          customRender: (text, record, index) => index + 1,
        },
        {
          title: "Waktu Pemeriksaan",
          dataIndex: "createdAtFormatted",
          key: "createdAt",
          sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        },
        {
          title: "Nama Dokter",
          dataIndex: "doctor_name",
          key: "doctor_name",
        },
        {
          title: "Keluhan Utama",
          dataIndex: "Anamnesis.keluhan_utama",
          key: "keluhan_utama",
        },
        {
          title: "Subjective",
          dataIndex: "DoctorNote.subjective",
          key: "subjective",
        },
        {
          title: "Objective",
          dataIndex: "DoctorNote.objective",
          key: "objective",
        },
        {
          title: "Assessment",
          dataIndex: "DoctorNote.assessment",
          key: "assessment",
        },
        {
          title: "Plan",
          dataIndex: "DoctorNote.plan",
          key: "plan",
        },
      ],
      columns: [
        {
          title: "No.",
          key: "no",
          customRender: (text, record, index) => index + 1,
        },
        {
          title: "Nama Pasien",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Tempat Lahir",
          dataIndex: "birth_place",
          key: "birth_place",
        },
        {
          title: "Tanggal Lahir",
          dataIndex: "birth_date",
          key: "birth_date",
        },
      ],
      activeKey: "1",
      role: "",
      dataSource: [],
      searchQuery: "",
      isModalVisible: false,
      selectedRowData: {},
      selectedRowPenunjang: {},
      selectedRowHistoryId: {},
      selectedRowHistory: [],
      isMobile: false,
      getDataLoading: false,
      showDokumenPenunjang: false,
      selectedDokumenPenunjang: {},
    }
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
